import React from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/core"
import Layout from "./layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Helmet from "react-helmet"
import { DiscussionEmbed } from "disqus-react"

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        subtitle
        author
        date
        slug
        keywords
      }
      body
      excerpt
    }
  }
`

const Post = ({ data: { mdx: post } }) => {
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: post.frontmatter.slug, post: post.frontmatter.title },
  }

  debugger
  return (
    <Layout>
      <Helmet
        title={`${post.frontmatter.title} | ${post.frontmatter.subtitle} | Dingus Games`}
      >
        <meta name="keywords" content={post.frontmatter.keywords} />
        <meta name="description" content={post.excerpt} />
      </Helmet>
      <section
        css={css`
          h1 {
            font-size: 3em;
            text-align: center;
            margin-bottom: 0.5em;
          }
          h2 {
            margin: 0;
          }
          p {
            margin-top: 0.5em;
          }
          hr {
            margin: 1em 0;
          }
          a {
            font-weight: 900;
            text-decoration: none;
            margin-left: -5px;
          }
          a:hover {
            text-decoration: underline;
          }
          .date {
            color: gray;
          }
        `}
      >
        <Link to="/blog">← Back to Blog</Link>
        <h1>{post.frontmatter.title}</h1>
        <h2>{post.frontmatter.subtitle}</h2>
        <p className="date">
          Posted by {post.frontmatter.author} on {post.frontmatter.date}
        </p>
        <hr></hr>
      </section>

      <MDXRenderer>{post.body}</MDXRenderer>

      <DiscussionEmbed {...disqusConfig} />
    </Layout>
  )
}
export default Post
